<template>
  <v-container>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
      :scrollable="$vuetify.breakpoint.xsOnly"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-form v-model="valid" ref="itemForm" @submit="item_submit">
        <v-card tile height="100%">
          <v-card-title class="pa-0">
            <v-app-bar>
              <v-app-bar-title>Proyecto</v-app-bar-title>
              <v-spacer></v-spacer>
              <v-icon @click="close()"> mdi-close </v-icon>
            </v-app-bar>
          </v-card-title>
          <v-card-text class="pt-3">
            <v-row class="pt-3">
              <v-col cols="12">
                <v-text-field
                  v-model="item.name"
                  label="Nombre"
                  hide-details="auto"
                  dense
                  :rules="f_required"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  outlined
                  v-model="item.project"
                  :rules="f_required"
                  label="Proyecto"
                  hide-details
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  outlined
                  v-model="item.description"
                  :rules="f_required"
                  label="Descripción"
                  hide-details
                ></v-textarea>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="item.issue_date"
                  label="Inicio"
                  hide-details="auto"
                  dense
                  :rules="f_required"
                  outlined
                  type="date"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="item.due_date"
                  label="Fin"
                  hide-details="auto"
                  dense
                  :rules="f_required"
                  outlined
                  type="date"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pa-0">
            <v-bottom-navigation grow>
              <v-btn @click="delete_itm(item)" :disabled="newItem">
                <span>Borrar</span>
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn :disabled="!valid" type="submit">
                <span>Guardar</span>
                <v-icon>mdi-content-save-outline</v-icon>
              </v-btn>
            </v-bottom-navigation>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog persistent max-width="290" v-model="dialog_confirm">
      <v-card>
        <v-card-title> Confirmar </v-card-title>
        <v-card-text> Desea eliminar el registro? </v-card-text>
        <v-card-actions>
          <v-btn text color="red" type="button" @click="dialog_confirm = !dialog_confirm">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="success" type="button" @click="delete_item">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { webserver } from "../services/webserver.js";
import createProject from "../utils/createParty.js";

export default {
  name: "ProjectForm",
  components: {},
  data() {
    return {
      edit: false,
      result: "",

      recenter: false,
      ipgeo: null,
      cities: [],
      countries: [],
      state: [],
      city: [],
      loading_status: false,
      tab: null,
      dialog_confirm: false,
      compressed_image: null,
      compressed_menu: null,
      reg_delete: null,
      valid: false,
      active_lst: [
        { value: "1", text: "Activo" },
        { value: "0", text: "InActivo" },
      ],
      f_required: [(v) => !!v || "Requerido"],
      f_email: [
        (v) => !!v || "Correo valido",
        (v) => /.+@.+/.test(v) || "Correo no valido",
      ],
      f_email_val: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Correo no valido",
      ],
      codeRules: [
        (v) => !!v || "El código es requerido",
        (v) => v.length === 4 || "EL código debe tener 4 caracteres.",
      ],
      stloc: null,
      note: "",
    };
  },
  props: {
    table: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
      default: createProject(),
    },

    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    newItem: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  mounted() {},
  methods: {
    item_submit(e) {
      e.preventDefault();
      this.valid = this.$refs.itemForm.validate();
      if (this.valid) {
        this.item.account = this.$store.state.profile.account;
        var qry = {
          account: this.$store.state.profile.account,
          table: this.table,
          data: [this.item],
        };
        webserver("put_table", qry, (data) => {
          console.log(data);
          this.compressed_image = null;
          this.loading_status = false;
          this.$emit("refresh", true);
        });
      }
    },
    delete_itm(data) {
      this.reg_delete = data.code;
      this.dialog_confirm = true;
    },
    delete_item() {
      var record = this.reg_delete;
      this.loading_status = true;
      var qry = {
        table: this.table,
        filters: {
          account: this.$store.state.profile.account,
          code: record,
        },
      };
      webserver("delete_record", qry, () => {
        this.reg_delete = null;
        this.dialog_confirm = false;
        this.loading_status = false;
        this.$emit("refresh", true);
      });
    },
    close() {
      this.$emit("close", false);
    },
    call_contact(data) {
      window.location.href = "tel://" + data;
    },
    country_change(e) {
      this.state = [];
      this.city = [];
      this.item.province = null;
      this.item.city = null;
      var stt = this.cities[e];
      this.state = Object.keys(stt).sort();
    },
    province_change(e) {
      this.city = [];
      this.item.city = null;
      this.city = this.cities[this.item.country][e].sort();
    },
    updateLocation(e) {
      this.item.location = JSON.stringify(e);
    },
    onDecode(e) {
      this.stopCamera = true;
      this.QRdialog = false;
      this.item.qr = e;
    },
    openQR() {
      this.stopCamera = false;
      this.QRdialog = true;
    },
    onLoaded(e) {
      console.log(e);
    },
    getLocation() {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (res) => {
              resolve([res.coords.latitude, res.coords.longitude]);
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
        } else {
          reject("No Geolocation support.");
        }
      });
    },
  },
  watch: {
    item: function (e) {
      if (e.picture) {
        this.productPhoto = this.getImagePhoto(e.account, e.code, e.picture);
      } else {
        this.productPhoto = null;
      }
      if (this.newItem) {
        this.edit = true;
      }
    },
  },
};
</script>

<style>
.v-btn--example {
  top: 10px;
  position: absolute;
  margin: 0 0 0 0;
}

.base-image-input {
  display: block;
  width: 100%;
  height: 250px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

.placeholder:hover {
  background: #e0e0e0;
}

.file-input {
  display: none;
}

.map {
  height: 400px;
}

.btn-upload {
  top: 150px;
}
</style>
