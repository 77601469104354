<template>
  <v-container>
    <v-toolbar flat>
      <v-toolbar-title>Proyectos</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn fab dark small color="success" @click="newForm">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-sheet elevation="0" class="pa-2 mt-3">
      <v-row class="pa-0">
        <v-col v-for="(child, index) in items" :key="index" cols="12" md="6" sm="6">
          <v-card elevation="10" height="100%" class="card-outter">
            <v-card-title v-text="child.name"></v-card-title>
            <v-card-subtitle
              >{{ child.issue_date }} / {{ child.due_date }}</v-card-subtitle
            >
            <v-card-text v-text="child.project"></v-card-text>
            <v-card-actions class="pa-2 card-actions">
              <v-btn @click="editItem(child)">Edit</v-btn>
              <v-spacer></v-spacer>

              <v-btn dark color="success" @click="view_events(child)">Tasks</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-sheet>
    <ProjectForm
      :table="table"
      :item="item"
      :dialog="dialog"
      :newItem="newItem"
      @close="dialog = false"
      @refresh="refresh()"
      @newOrder="newOrder(item)"
    />
  </v-container>
</template>

<script>
import { webserver, getIssueDate, getToday } from "../../services/webserver.js";
import createItem from "../../utils/create_project.js";
import ProjectForm from "../../components/ProjectForm.vue";

export default {
  components: { ProjectForm },
  mounted() {
    this.get_items();
  },
  data() {
    return {
      table: "road_project",
      chosenPhoto: null,

      validMsg: false,
      broadcast: { email: null, subject: null, msg: null },
      broadcast_dialog: false,
      dialog: false,
      newItem: false,
      search: "",
      loading_status: false,
      errors: [],
      items: [],
      item: createItem(),
      headers: {
        false: [
          {
            text: "Nombre",
            align: "start",
            sortable: true,
            value: "name",
            dataType: "text",
          },
          {
            text: "Grupo",
            align: "start",
            sortable: true,
            value: "group_name",
            dataType: "text",
          },
          {
            text: "Dirección",
            align: "start",
            sortable: true,
            value: "address",
            dataType: "text",
          },
          {
            text: "Ciudad",
            align: "start",
            sortable: true,
            value: "city",
            dataType: "text",
          },
          {
            text: "Note",
            align: "start",
            sortable: true,
            value: "note",
            dataType: "text",
            width: "30%",
          },
          {
            text: "Fecha",
            align: "start",
            sortable: true,
            value: "issue_date",
            dataType: "text",
          },
          {
            text: "Teléfono",
            align: "end",
            sortable: true,
            value: "mobile",
            dataType: "text",
          },
        ],
        true: [
          {
            text: "Nombre",
            align: "start",
            sortable: true,
            value: "name",
            dataType: "text",
          },
          {
            text: "",
            align: "start",
            sortable: true,
            value: "city",
            dataType: "text",
          },
          {
            text: "Teléfono",
            align: "end",
            sortable: true,
            value: "mobile",
            dataType: "text",
          },
        ],
      },
      issueDateFrom: getIssueDate(5),
      issueDateTo: getToday(),
      groupby: "",
      groups: [],
    };
  },
  methods: {
    editItem(data) {
      this.item = { ...data };
      this.newItem = false;
      this.dialog = true;
    },
    view_events(e) {
      this.item = e;
      this.$router.push({ name: "Tasks", params: { project: e } });
    },
    get_items() {
      var qry = {
        account: this.$store.state.profile.account,
        table: this.table,
        filters: [
          { field: "account", operator: "=", value: this.$store.state.profile.account },
        ],
      };

      this.loading_status = true;
      webserver("get_dataTable", qry, (data) => {
        this.loading_status = false;
        this.items = data;
      });
    },
    refresh() {
      this.dialog = false;
      this.get_items();
    },
    getImagePhoto: function (account, code, src) {
      var currentdate = new Date();
      var url =
        "https://" +
        this.$store.getters.getHost +
        "/" +
        account +
        "/clients/" +
        src +
        "?" +
        currentdate;
      return url;
    },
    newForm() {
      this.item = createItem();
      this.item.account = this.$store.state.profile.account;
      //   this.item.store = this.$store.state.company.code;
      this.valid = false;
      this.newItem = true;
      this.dialog = true;
    },
    call_contact(data) {
      window.location.href = "tel://" + data;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-outter {
  padding-bottom: 50px;
}
.card-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>
